import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroText from "../components/heroText"
import PricingForm from "../components/forms/pricingForm"
import TextGroup from "../components/ui/textGroup"

const PricingPage = () => (
  <Layout>
    <SEO title="Price Calculator" />
    <HeroText
      statement="Pricing"
      caption="Do you want to find out how much Beyond Attendance will cost you per month?"
    ></HeroText>

    <TextGroup
      heading="Instant Price Calculator"
      text={
        <>
          <p>We have a scaled, tier based pricing model with a month to month contract and no fixed Terms. Enter the number of people in your organisation to get an accurate, automated costing.</p>
        </>
      }>
      <PricingForm />
    </TextGroup>
  </Layout>
)

export default PricingPage
